/* tslint:disable */
/* eslint-disable */
/**
 * Ecommerce
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CheckStatus200Response
 */
export interface CheckStatus200Response {
    /**
     * 
     * @type {boolean}
     * @memberof CheckStatus200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CheckStatus200Response
     */
    'data'?: object;
    /**
     * 
     * @type {string}
     * @memberof CheckStatus200Response
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface GetAdministratorList200Response
 */
export interface GetAdministratorList200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAdministratorList200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof GetAdministratorList200Response
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    'identificativo'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    'nome'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    'indirizzo'?: string;
}
/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRequest
     */
    'id'?: number;
}

/**
 * AdministratorApi - axios parameter creator
 * @export
 */
export const AdministratorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdministratorList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/getAdministratorList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministratorApi - functional programming interface
 * @export
 */
export const AdministratorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdministratorApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdministratorList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdministratorList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdministratorList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdministratorApi - factory interface
 * @export
 */
export const AdministratorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdministratorApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdministratorList(options?: any): AxiosPromise<GetAdministratorList200Response> {
            return localVarFp.getAdministratorList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdministratorApi - object-oriented interface
 * @export
 * @class AdministratorApi
 * @extends {BaseAPI}
 */
export class AdministratorApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorApi
     */
    public getAdministratorList(options?: AxiosRequestConfig) {
        return AdministratorApiFp(this.configuration).getAdministratorList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendar(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendar(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendar(options?: any): AxiosPromise<CheckStatus200Response> {
            return localVarFp.calendar(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public calendar(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).calendar(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {LoginRequest} loginRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {LoginRequest} loginRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {LoginRequest} loginRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<CheckStatus200Response> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {LoginRequest} loginRequest Get category data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {SearchRequest} searchRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (searchRequest: SearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            assertParamExists('search', 'searchRequest', searchRequest)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {SearchRequest} searchRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(searchRequest: SearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {SearchRequest} searchRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(searchRequest: SearchRequest, options?: any): AxiosPromise<CheckStatus200Response> {
            return localVarFp.search(searchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {SearchRequest} searchRequest Get category data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public search(searchRequest: SearchRequest, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).search(searchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/checkStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkStatus(options?: any): AxiosPromise<CheckStatus200Response> {
            return localVarFp.checkStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public checkStatus(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).checkStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {UserRequest} userRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('user', 'userRequest', userRequest)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {UserRequest} userRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {UserRequest} userRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(userRequest: UserRequest, options?: any): AxiosPromise<CheckStatus200Response> {
            return localVarFp.user(userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {UserRequest} userRequest Get category data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public user(userRequest: UserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).user(userRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


