
import { defineComponent } from "vue";

export default defineComponent({
  name: "fe-header",

  components: {},
  computed: {},
  data() {
    return {};
  },
});
