import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/views/SearchPage.vue')
  },
  {
    name: 'calendar',
    path: '/calendar',
    component: () => import('@/views/CalendarPage.vue')
  },
  {
    name: 'user',
    path: '/user/:id',
    component: () => import('@/views/UserPage.vue')
  },
  {
    name: 'administratorList',
    path: '/administratorList',
    component: () => import('@/views/AdministratorPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (!localStorage.getItem('token') && to.path !== '/login')
    return { path: '/login' }
  else if (localStorage.getItem('token') && to.path == '/login')
    return { name: 'home' }
})

export default router
