import { LoginApiFactory, SearchApiFactory, UserApiFactory, CalendarApiFactory, StatusApiFactory, AdministratorApiFactory } from "../src/api"
import axios from 'axios'
import router from '../src/router'

const basePath = 'https://pratoecologia.teramnall.it'

export const LoginApi = LoginApiFactory(undefined, basePath)
export const SearchApi = SearchApiFactory(undefined, basePath)
export const UserApi = UserApiFactory(undefined, basePath)
export const CalendarApi = CalendarApiFactory(undefined, basePath)
export const StatusApi = StatusApiFactory(undefined, basePath)
export const AdministratorApi = AdministratorApiFactory(undefined, basePath)

axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
    
      if (token) {
        if (!config.headers)
          config.headers = { Authorization: ''}

        config.headers["Authorization"] = 'Bearer ' + token
        config.headers["Content-Type"] = "text/plain"
     }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  
  axios.interceptors.response.use(
    (response) => {
      if (response.data.token === null) {
        localStorage.removeItem("token")
        router.push({name: 'login'});
        return
      }

      if (response.data.token && response.data.token.token) 
        localStorage.setItem("token", response.data.token.token)

      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )