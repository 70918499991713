
import { defineComponent } from "vue";

export default defineComponent({
  name: "fe-footer",

  components: {
  },

  data() {
    return {
    };
  },
});
