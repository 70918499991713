
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import FeHeader from "@/components/Header.vue";
import FeFooter from "@/components/Footer.vue";
import { StatusApi } from "../axios/axios";
import { App } from '@capacitor/app';

App.addListener('backButton', ({ canGoBack }: { canGoBack: any}) => {
  if(canGoBack){
    window.history.back();
  } else {
    App.exitApp();
  }
});

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    FeHeader,
    FeFooter,
  },

  data() {
    return {
      timeout: null as number | null,
      a: 0,
      isTimeoutActive: false,
    };
  },

  async beforeMount() {
    await StatusApi.checkStatus();
  }
});
