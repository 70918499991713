
import { defineComponent } from "vue";
//import { IonItem, IonInput, IonButton, IonIcon } from "@ionic/vue";
import { calendarOutline, searchOutline, peopleOutline } from "ionicons/icons";
import { SearchApi } from "../../axios/axios";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  components: {
    IonIcon,
  },

  data() {
    return {
      nome: "",
      address: "",
      requestInProgress: false,
      calendarOutline,
      searchOutline,
      peopleOutline,
      result: null as any,
    };
  },
  methods: {
    viewUser(id: number): void {
      this.$router.push({ name: "user", params: { id: id } });
    },

    async search() {
      if (this.requestInProgress) return;

      this.requestInProgress = true;
      const result = (await SearchApi.search({
        nome: this.nome,
        indirizzo: this.address,
      })) as any;

      this.requestInProgress = false;
      this.result = null;

      if (result.data.token === null) this.$router.push({ name: "login" });

      if (!result.data.success) return;

      this.result = result.data.data;
    },
  },
});
